import { Platform } from 'react-native';
import 'react-native-reanimated'; // https://moti.fyi/installation#using-inline-requires
import registerRootComponent from 'expo/build/launch/registerRootComponent';
import { activateKeepAwake } from 'expo-keep-awake';
import 'expo-asset';
import * as SplashScreen from '@src/lib/splashScreen';
import '@src/amplitude';
import '@src/sentry';
import loadingPromise from '@src/lib/mountApp.platform';

if (__DEV__) {
  activateKeepAwake();
}

export function mountApp(app: React.ComponentType<any>) {
  if (Platform.OS !== 'web') {
    SplashScreen.preventAutoHideAsync().catch(() => {
      // noop, prevent unused warning about splash already hidden
    });
  }
  loadingPromise.then(() => {
    registerRootComponent(app);
  });
}
