import React, { useMemo } from 'react';
import { Platform, StyleSheet, Image, View } from 'react-native';
import RNMarkdown, { hasParents } from 'react-native-markdown-display';
import merge from 'lodash/merge';

import { Text } from '@src/components/Text';

// Images https://ouitherapeutics.slack.com/archives/CNRUWC7RC/p1570120768018000
export function Markdown(props: {
  children: React.ReactNode;
  fontFamily?: string;
  style?: StyleSheet.NamedStyles<any>;
  textColor?: string;
}) {
  const style: StyleSheet.NamedStyles<any> = useMemo(() => {
    const defaultStyle = {
      root: {
        width: '100%',
        fontSize: 16,
        fontFamily: props.fontFamily || 'OpenSansRegular',
        color: props.textColor,
      },
      headingContainer: { paddingVertical: 10 },
      heading1: {
        fontSize: 32,
        fontFamily: 'OpenSansBold',
      },
      heading2: {
        fontSize: 24,
        fontFamily: 'OpenSansBold',
      },
      heading3: {
        fontSize: 18,
        fontFamily: 'OpenSansBold',
      },
      heading4: {
        fontSize: 16,
        fontFamily: 'OpenSansBold',
      },
      heading5: {
        fontSize: 13,
        fontFamily: 'OpenSansBold',
      },
      heading6: {
        fontSize: 11,
        fontFamily: 'OpenSansBold',
      },
      strong: {
        fontSize: 16,
        fontFamily: 'OpenSansBold',
        color: props.textColor,
      },
      listUnorderedItemIcon: {
        marginTop: 10,
        backgroundColor: 'black',
        borderRadius: 5,
        height: 5,
        marginRight: 10,
        width: 5,
      },
      listItem: { flexShrink: 1 },
    };

    return merge({}, defaultStyle, props.style);
  }, [props.textColor, props.fontFamily, props.style]);

  return (
    <RNMarkdown
      style={style}
      rules={{
        image: (node, children, parent, styles) => {
          let content = null;
          try {
            if (node.content && node.content.startsWith('{')) {
              content = JSON.parse(node.content);
            }
          } catch (e) {}
          return (
            <Image
              key={node.key}
              style={[
                styles.image,
                {
                  flex: 0,
                  flexGrow: 1,
                  resizeMode: 'contain',
                  minHeight: 50,
                  alignSelf: 'flex-start',
                },
                content,
              ]}
              source={{
                uri: node.attributes.src,
              }}
            />
          );
        },
        paragraph: (node, children, parent, styles) => {
          const anyParent = (parent as any)?.[0];
          const parentIsRoot = anyParent.type === 'root';
          const isFirstRootChild = parentIsRoot && node.key === anyParent.children[0]?.key;
          const isLastRootChild =
            parentIsRoot && node.key === anyParent.children[anyParent.children.length - 1]?.key;
          const isInListItem = hasParents(parent as any, 'list_item');
          const hasImageChild = !!node.children.find((child: any) => child.sourceType === 'image');
          return (
            <View
              key={node.key}
              style={[
                styles.paragraph,
                isFirstRootChild ? { marginTop: 0 } : 0,
                isLastRootChild ? { marginBottom: 0 } : 0,
                isInListItem ? { marginTop: 0, marginBottom: 5 } : null,
                hasImageChild ? { alignItems: 'center' } : null,
              ]}
            >
              {children}
            </View>
          );
        },
        list_item: (node, children, parent, styles) => {
          if (hasParents(parent as any, 'bullet_list')) {
            return (
              <View key={node.key} style={styles.listUnorderedItem}>
                <View style={styles.listUnorderedItemIcon} />
                <View style={[styles.listItem]}>{children}</View>
              </View>
            );
          }

          if (hasParents(parent as any, 'ordered_list')) {
            const depth = (parent as any).filter((p: typeof node) => p.type === 'ordered_list')
              .length;
            const isAlphaList = depth % 2 === 0;
            return (
              <View key={node.key} style={[styles.listOrderedItem]}>
                <Text
                  style={[
                    styles.listOrderedItemIcon,
                    {
                      lineHeight: Platform.select({ default: undefined, android: 18 }),
                      alignSelf: 'flex-start',
                    },
                  ]}
                  text={`${
                    isAlphaList
                      ? String.fromCharCode('a'.charCodeAt(0) + node.index)
                      : node.index + 1
                  }`}
                />
                <View style={[styles.listItem]}>{children}</View>
              </View>
            );
          }

          return (
            <View key={node.key} style={[styles.listItem]}>
              {children}
            </View>
          );
        },
      }}
    >
      {props.children}
    </RNMarkdown>
  );
}

const EXAMPLE = `
Headings

  # h1 Heading 8-)
  ## h2 Heading
  ### h3 Heading
  #### h4 Heading
  ##### h5 Heading
  ###### h6 Heading


Horizontal Rules

  Some text above
  ___

  Some text in the middle

  ---

  Some text below


Typographic Replacements

  Enable typographer option to see result.

  (c) (C) (r) (R) (tm) (TM) (p) (P) +-

  test.. test... test..... test?..... test!....

  !!!!!! ???? ,,  -- ---

  "Smartypants, double quotes" and 'single quotes'


Emphasis

  **This is bold text**

  __This is bold text__

  *This is italic text*

  _This is italic text_

  ~~Strikethrough~~


Blockquotes

  > Blockquotes can also be nested...
  >> ...by using additional greater-than signs right next to each other...
  > > > ...or with spaces between arrows.


Lists

  Unordered

  + Create a list by starting a line with \`+\`, \`-\`, or \`*\`
  + Sub-lists are made by indenting 2 spaces:
    - Marker character change forces new list start:
      * Ac tristique libero volutpat at
      + Facilisis in pretium nisl aliquet
      - Nulla volutpat aliquam velit
  + Very easy!

  Ordered

  1. Lorem ipsum dolor sit amet
  2. Consectetur adipiscing elit
  3. Integer molestie lorem at massa


  1. You can use sequential numbers...
  1. ...or keep all the numbers as \`1.\`

  Start numbering with offset:

  57. foo
  1. bar


Code

  Inline \`code\`

  Indented code

      // Some comments
      line 1 of code
      line 2 of code
      line 3 of code


  Block code "fences"

  \`\`\`
  Sample text here...
  \`\`\`

  Syntax highlighting

  \`\`\` js
  var foo = function (bar) {
    return bar++;
  };

  console.log(foo(5));
  \`\`\`


Tables

  | Option | Description |
  | ------ | ----------- |
  | data   | path to data files to supply the data that will be passed into templates. |
  | engine | engine to be used for processing templates. Handlebars is the default. |
  | ext    | extension to be used for dest files. |

  Right aligned columns

  | Option | Description |
  | ------:| -----------:|
  | data   | path to data files to supply the data that will be passed into templates. |
  | engine | engine to be used for processing templates. Handlebars is the default. |
  | ext    | extension to be used for dest files. |


Links

  [link text](https://www.google.com)

  [link with title](https://www.google.com "title text!")

  Autoconverted link https://www.google.com (enable linkify to see)


Images

  ![Minion](https://octodex.github.com/images/minion.png)
  ![Stormtroopocat](https://octodex.github.com/images/stormtroopocat.jpg "The Stormtroopocat")

  Like links, Images also have a footnote style syntax

  ![Alt text][id]

  With a reference later in the document defining the URL location:

  [id]: https://octodex.github.com/images/dojocat.jpg  "The Dojocat"
  `;

export function MarkdownExample() {
  return <Markdown>{EXAMPLE}</Markdown>;
}
