import React, { forwardRef, useRef, useState } from 'react';
import {
  AccessibilityProps,
  Platform,
  StyleProp,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
  View,
  GestureResponderEvent,
  Pressable,
} from 'react-native';
import { TouchableOpacity as GHTouchableOpacity } from 'react-native-gesture-handler';
import ColorLib from 'color';

import { Theme, useTheme } from '@src/styles';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { Text } from '@src/components/Text';
import { Icon } from '@src/components/Icon';
import { addBreadcrumb } from '@src/lib/log';
import Sentry from '@src/sentry';
import { useAccessibilityContext } from '@src/components/AccessibilityContext';
import _hexToRgba from 'hex-to-rgba';
import { isInteractionPaused } from '@src/lib/pauseInteraction';

type Props = Pick<
  AccessibilityProps,
  'accessibilityState' | 'accessibilityActions' | 'onAccessibilityAction'
> & {
  _useGestureHandler?: boolean;
  _accentColor?: string;
  alignSelf?: 'stretch' | 'center' | 'flex-start' | 'flex-end';
  children?: React.ReactNode;
  color?: string;
  /** a user has to perform an action or series of actions on the page in order to enable the button */
  disabled?: boolean;
  /** a user must wait for something else to happen outside their immediate control in order for the button to enable */
  unavailable?: boolean;
  hideIconOnLoading?: boolean;
  icon?: React.ComponentProps<typeof Icon>['name'];
  iconRight?: React.ComponentProps<typeof Icon>['name'];
  iconSize?: number;
  loading?: boolean;
  onPress: undefined | (() => unknown | Promise<unknown>);
  pressOnce?: boolean;
  skipOnPress?: boolean;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  text?: string;
  textStyle?: StyleProp<TextStyle>;
  size?: keyof Theme['button'];
  variant?: 'contained' | 'solid' | 'text';
} & (
    | { text?: never; accessibilityLabel: string | undefined }
    | { text: string; accessibilityLabel?: string }
  );

export const Button = forwardRef<TouchableOpacity, Props>(function Button(props, ref) {
  const { Color, theme, Shadow } = useTheme();
  const pressedOnceRef = useRef(false);
  const [_loading, setLoading] = useState(false);
  const [pressedIn, setIsPressedIn] = useState(false);
  const loading = _loading || props.loading;
  const hasContent = !!(props.text || props.children);
  const accentColor = props._accentColor ?? theme.color.primary100;
  const size = props.size ?? 'normal';
  const variant = props.variant ?? 'contained';
  let color = props.unavailable
    ? variant === 'solid'
      ? 'white'
      : theme.color.gray500
    : props.color || (variant === 'solid' ? 'white' : accentColor);
  if (pressedIn) {
    color = ColorLib(color).darken(0.2).toString();
  }
  const { isScreenReaderEnabled } = useAccessibilityContext();
  const disabled = props.disabled || props.unavailable || loading;

  const isPressable = !(props._useGestureHandler && Platform.OS !== 'web');
  const Component = isPressable ? Pressable : GHTouchableOpacity;
  const buttonTheme = theme.button[size];

  const iconSize = props.iconSize ?? buttonTheme.iconSize;
  const style: React.ComponentProps<typeof Pressable>['style'] = ({
    pressed,
    // @ts-expect-error
    hovered,
  }) => {
    return [
      {
        backgroundColor: Color.surfaceColor,
        borderRadius: buttonTheme.borderRadius,
        paddingVertical: buttonTheme.paddingVertical,
        paddingHorizontal: hasContent ? buttonTheme.paddingHorizontal : buttonTheme.paddingVertical,
        borderWidth: 2,
        borderColor: accentColor,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: props.alignSelf,
      },
      variant === 'solid' ? { backgroundColor: accentColor } : null,
      variant === 'text'
        ? {
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            alignSelf: props.alignSelf || 'flex-start',
            paddingHorizontal: 0,
          }
        : null,
      props.disabled ? { opacity: 0.5 } : null,
      props.unavailable
        ? variant === 'contained'
          ? { borderColor: theme.color.gray500 }
          : variant === 'solid'
          ? { backgroundColor: theme.color.gray600, borderColor: theme.color.gray600 }
          : null
        : null,
      props.style,
      pressed && variant === 'solid' && !props.skipOnPress
        ? [
            {
              backgroundColor: ColorLib(accentColor).darken(0.2).toString(),
              borderColor: ColorLib(accentColor).darken(0.2).toString(),
            },
            Platform.OS === 'web' ? Shadow.low : null,
          ]
        : undefined,
      hovered && variant === 'solid' && !props.skipOnPress
        ? {
            backgroundColor: ColorLib(accentColor).darken(0.2).toString(),
            borderColor: ColorLib(accentColor).darken(0.2).toString(),
          }
        : undefined,
      hovered && variant === 'contained' && !props.skipOnPress
        ? { backgroundColor: ColorLib(accentColor).lighten(0.8).toString() }
        : undefined,
      pressed && variant === 'contained' && !props.skipOnPress
        ? [{ backgroundColor: ColorLib(accentColor).lighten(0.8).toString() }, Shadow.low]
        : undefined,
    ];
  };

  return (
    <Component
      accessibilityLabel={props.accessibilityLabel}
      accessibilityRole="button"
      accessibilityState={{
        disabled: !!(props.disabled || props.unavailable),
        ...props.accessibilityState,
        // busy: !!loading, Doesn't seem to reset properly on Android
      }}
      accessibilityActions={props.accessibilityActions}
      onAccessibilityAction={props.onAccessibilityAction}
      importantForAccessibility={
        props.accessibilityLabel || props.text ? 'yes' : 'no-hide-descendants'
      }
      accessibilityElementsHidden={!(props.accessibilityLabel || props.text)}
      ref={ref}
      testID={props.testID}
      // disabled prop makes button invisible to iOS screen reader if true
      disabled={!isScreenReaderEnabled && disabled}
      onPressIn={() => setIsPressedIn(true)}
      onPressOut={() => setIsPressedIn(false)}
      onPress={(evt?: GestureResponderEvent) => {
        if (props.skipOnPress) return;
        if (disabled) return;
        if (isInteractionPaused()) return;
        addBreadcrumb({
          category: 'ui.click',
          message: props.testID ?? props.text,
          data: {
            componentType: 'Button',
            testID: props.testID,
            icon: props.icon,
            iconRight: props.iconRight,
            text: props.text,
          },
        });
        // On web sometimes we have a button inside of a Link which causes both to be pressed
        // unless we preventDefault
        if (Platform.OS === 'web' && evt) evt.preventDefault();
        if (props.pressOnce && pressedOnceRef.current) return;
        pressedOnceRef.current = true;
        if (props.onPress) {
          const result = props.onPress();
          if (result === Promise.resolve(result)) {
            setLoading(true);
            (result as Promise<any>)
              .catch((e) => e)
              .then((r) => {
                addBreadcrumb({
                  category: 'ui.click.async-done',
                  message: props.testID ?? props.text ?? props.icon,
                  data: {
                    error: r instanceof Error,
                  },
                });
                setLoading(false);
                pressedOnceRef.current = false;
                if (r instanceof Error) {
                  Sentry.captureException(r);
                  throw r;
                }
              });
          } else {
            pressedOnceRef.current = false;
          }
        }
      }}
      style={isPressable ? style : style({ pressed: false })}
      // @ts-expect-error
      dataSet={{
        button: '',
        'button--text': variant === 'text' ? '' : undefined,
      }}
    >
      {loading ? <ActivityIndicator color={color} style={{ marginRight: 10 }} /> : null}
      {props.icon && (!loading || !props.hideIconOnLoading) ? (
        <Icon
          name={props.icon}
          color={color}
          size={iconSize || 20}
          style={hasContent ? { marginRight: 10 } : null}
        />
      ) : null}
      {props.text ? (
        <Text
          text={props.text}
          color={color}
          weight="semibold"
          textAlign={props.alignSelf === 'center' ? 'center' : undefined} // may always want center
          style={[props.textStyle, { lineHeight: buttonTheme.lineHeight }]}
          size={buttonTheme.fontSize}
        />
      ) : (
        props.children
      )}
      {props.iconRight && (!props.hideIconOnLoading || !loading) ? (
        <Icon
          name={props.iconRight}
          color={color}
          size={iconSize || 20}
          style={hasContent ? { marginLeft: 10 } : null}
        />
      ) : null}
      {pressedIn && variant === 'text' && Platform.OS !== 'web' ? (
        <View
          style={{
            height: 1,
            position: 'absolute',
            backgroundColor: color,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        />
      ) : null}
    </Component>
  );
});
