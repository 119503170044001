import React from 'react';

import { Color } from '@src/styles';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { APP_SLUG } from '@src/constants';

function FooterText({ text }: { text: string }) {
  return <Text text={text} size={13} color={Color.styleGuide.Gray4} />;
}
const year = new Date().getFullYear();
export default function Footer(props: { hideBorderTop?: boolean }) {
  const supportEmail = 'support@ouitherapeutics.com';

  return (
    <View
      style={{
        borderTopWidth: props.hideBorderTop ? 0 : 1,
        borderTopColor: '#454154',
        backgroundColor: '#F7F7F9',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          alignItems: 'center',
          flexDirection: 'column',
          maxWidth: 940,
          padding: 30,
          width: '100%',
        }}
        spacing={20}
      >
        <FooterText text={`© ${year} ${'Oui Therapeutics, Inc.'}`} />
        {APP_SLUG === 'oui-therapeutics' ? (
          <FooterText text="Aviva is property of Oui Therapeutics, Inc." />
        ) : null}
        <>
          <View row>
            <FooterText text="Contact us at " />
            <a
              href={`mailto:${supportEmail}`}
              target="_blank"
              rel="noreferrer noopener"
              style={{
                fontSize: 13,
                fontFamily: 'OpenSansRegular',
                color: Color.styleGuide.Gray4,
              }}
            >
              {supportEmail}
            </a>
          </View>
          <FooterText text={`${'Oui Therapeutics'}, 4 Science Park, New Haven, CT 06511`} />
        </>

        <a
          href="/terms-and-privacy"
          style={{
            fontSize: 13,
            fontFamily: 'OpenSansRegular',
            color: Color.styleGuide.Gray4,
          }}
        >
          Terms of Service & Privacy Policy
        </a>
        {APP_SLUG === 'corporate' ? (
          <a
            href="/fcoi"
            style={{
              fontSize: 13,
              fontFamily: 'OpenSansRegular',
              color: Color.styleGuide.Gray4,
            }}
          >
            FCOI Policy
          </a>
        ) : null}
      </View>
    </View>
  );
}
