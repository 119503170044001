import React from 'react';
import { ScrollView } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';

import { useWindowDimensions } from '@src/hooks/useWindowDimensions';
import { Text, OldHeading } from '@src/components/Text';
import { Button } from '@src/components/Button';
import { View } from '@src/components/View';
import { StaticWebContainer, Section, Row } from '@src/components/StaticWebContainer';

import Footer from '@src/components/Footer';
import ComingSoon from '@src/components/ComingSoon';
import TermsAndPrivacy from '@src/components/TermsAndPrivacy';
import { AvivaLogoTitle } from '@src/components/AvivaLogoTitle';
import { Color } from '@src/styles';
import { DEFAULT_HEADER_MODE } from '@src/constants';

import EngagingTreatment from '@src/assets/about/EngagingTreatment.svg';
import Chatbot from '@src/assets/about/Chatbot.svg';
import Encouragement from '@src/assets/about/Encouragement.svg';

function Home() {
  const { width } = useWindowDimensions();
  const isMobile = width < 600;

  return (
    <ScrollView contentContainerStyle={{ flex: 1 }}>
      <Section
        style={{
          borderBottomWidth: 1,
          borderBottomColor: '#454154',
        }}
      >
        <View
          row={!isMobile}
          style={{
            alignItems: isMobile ? undefined : 'flex-start',
            paddingTop: 20,
            paddingHorizontal: 20,
          }}
        >
          <View style={{ flex: 3, marginRight: isMobile ? 0 : -160, zIndex: 1 }}>
            <OldHeading
              text="Manage troubling thoughts with Aviva"
              style={[
                {
                  marginTop: 20,
                  fontSize: 46,
                  lineHeight: 65,
                  alignSelf: 'center',
                  zIndex: 1,
                  fontFamily: 'OpenSansSemiBold',
                },
                isMobile ? { fontSize: 42, lineHeight: 57, fontFamily: 'OpenSansSemiBold' } : null,
              ]}
            />
            <Text
              text="Aviva is built on science shown to help people manage troubling thoughts. It’s a directed and personalized program."
              size={17}
              style={{ lineHeight: 23 }}
            />
          </View>
          <View style={{ flex: 4 }}>
            <ComingSoon />
          </View>
        </View>
      </Section>
      <Section backgroundColor="#F4F8F5">
        <Row
          asset={<Chatbot accessibilityLabel={undefined} />}
          heading="Aviva includes a chatbot designed to engage with you"
          content="Aviva has a chatbot that goes back and forth with users to gather specific issues and develop a course of exercises and information tailored for individual needs."
        />
      </Section>
      <Section>
        <Row
          assetLast
          asset={<EngagingTreatment accessibilityLabel={undefined} />}
          heading="Aviva delivers safe, easy to use, and easy to understand tools"
          content="Aviva is an app with a variety of animated explanations, videos based on real-life examples, and other digital resources designed to help you manage what might be troubling you."
        />
      </Section>
      <Section backgroundColor="#F4F8F5">
        <Row
          asset={<Encouragement accessibilityLabel={undefined} />}
          heading="Aviva is accessible throughout your journey"
          content="When you have Aviva on your device you can access tools to learn the skills you need, practice techniques shown to work, and receive encouragement along the way."
        />
      </Section>
      <Footer />
    </ScrollView>
  );
}

const Stack = createStackNavigator();
const Main = () => {
  return (
    <Stack.Navigator
      initialRouteName="Home"
      screenOptions={{
        headerMode: DEFAULT_HEADER_MODE,
        cardStyle: { backgroundColor: 'white' },
        headerTitleAlign: 'center',
        title: '',
        headerLeftContainerStyle: { paddingLeft: 12 },
        headerRightContainerStyle: { paddingRight: 12 },
        headerLeft: () => (
          <a href="/">
            <AvivaLogoTitle color={Color.styleGuide.LogoLilac} />
          </a>
        ),
        headerRight: () => (
          <Button
            text="Contact us"
            onPress={() => window.open('mailto:support@ouitherapeutics.com', '_blank')}
            textStyle={{ fontFamily: 'OpenSansSemiBold' }}
          />
        ),
        headerStyle: {
          borderBottomWidth: 0,
        },
      }}
    >
      <Stack.Screen name="Home" component={Home} />
      <Stack.Screen name="TermsAndPrivacy" component={TermsAndPrivacy} />
    </Stack.Navigator>
  );
};

export default function Vitahealth() {
  return <StaticWebContainer app={<Main />} />;
}
