import React from 'react';
import { Platform, processColor } from 'react-native';

import { View } from '@src/components/View';
import { Logo } from '@src/components/ScreenHeader';

import Wordmark from '@src/assets/Wordmark.svg';

export function AvivaLogoTitle({ color = 'white' }: { color?: string }) {
  const a11yProps = {
    [Platform.OS === 'web'
      ? (('aria-label' as any) as 'accessibilityLabel')
      : 'accessibilityLabel']: 'Aviva',
  };

  return (
    <View row spacing={8}>
      <Logo width={30} height={30} color={color !== 'white' && color !== 'red'} />
      <Wordmark
        color={Platform.OS === 'ios' ? ((processColor(color) as any) as string) : color}
        {...a11yProps}
      />
    </View>
  );
}
