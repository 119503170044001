// Adapted from https://github.com/getsentry/sentry-rrweb/blob/master/src/index.ts
// to support lazy loading & full set of rrweb options
import type { EventType, record } from 'rrweb';
import * as Sentry from '@sentry/browser';
import { Dsn } from '@sentry/types';

type RRWebEvent = {
  type: EventType;
  data: {};
  timestamp: number;
  delay?: number;
};

export default class SentryRRWeb {
  public readonly name: string = SentryRRWeb.id;
  public static id: string = 'SentryRRWeb';

  public events: Array<RRWebEvent> = [];
  private readonly checkoutEveryNms: number;
  private readonly checkoutEveryNth?: number;

  // defaults to true
  private readonly maskAllInputs: boolean;

  public constructor({
    checkoutEveryNms,
    checkoutEveryNth,
    maskAllInputs = true,
    ...rest
  }: Parameters<typeof record>[0] = {}) {
    // default checkout time of 5 minutes
    this.checkoutEveryNms = checkoutEveryNms || 5 * 60 * 1000;
    this.checkoutEveryNth = checkoutEveryNth;
    this.maskAllInputs = maskAllInputs;

    this.events = [];

    import('rrweb').then((rrweb) => {
      rrweb.record({
        ...rest,
        checkoutEveryNms: this.checkoutEveryNms,
        checkoutEveryNth: this.checkoutEveryNth,
        maskAllInputs: this.maskAllInputs,
        emit: (event: RRWebEvent, isCheckout?: boolean) => {
          if (isCheckout) {
            this.events = [event];
          } else {
            this.events.push(event);
          }
        },
      });
    });
  }

  public attachmentUrlFromDsn(dsn: Dsn, eventId: string) {
    const { host, path, projectId, port, protocol, user } = dsn;
    return `${protocol}://${host}${port !== '' ? `:${port}` : ''}${
      path !== '' ? `/${path}` : ''
    }/api/${projectId}/events/${eventId}/attachments/?sentry_key=${user}&sentry_version=7&sentry_client=rrweb`;
  }

  public setupOnce() {
    Sentry.addGlobalEventProcessor((event) => {
      const self = Sentry.getCurrentHub().getIntegration(SentryRRWeb);
      if (!self) return;
      try {
        // short circuit if theres no events to replay
        if (!this.events.length) return;
        const client = Sentry.getCurrentHub().getClient();
        const endpoint = self.attachmentUrlFromDsn(client!.getDsn()!, event!.event_id!);
        const formData = new FormData();
        formData.append(
          'rrweb',
          new Blob([JSON.stringify({ events: self.events })], {
            type: 'application/json',
          }),
          'rrweb.json',
        );
        fetch(endpoint, {
          method: 'POST',
          body: formData,
        }).catch((ex) => {
          // we have to catch this otherwise it throws an infinite loop in Sentry
          console.error(ex);
        });
        return event as any;
      } catch (ex) {
        console.error(ex);
      }
    });
  }
}
