import * as Font from 'expo-font';
import MaterialIcons from '@expo/vector-icons/build/MaterialIcons';

export function initFonts() {
  return Font.loadAsync({
    OpenSansBold: {
      uri: require('../assets/font/OpenSans-Bold.ttf'),
      display: Font.FontDisplay.FALLBACK,
    },
    OpenSansRegular: {
      uri: require('../assets/font/OpenSans-Regular.ttf'),
      display: Font.FontDisplay.FALLBACK,
    },
    OpenSansSemiBold: {
      uri: require('../assets/font/OpenSans-SemiBold.ttf'),
      display: Font.FontDisplay.FALLBACK,
    },
    SourceSerifProSemiBold: {
      uri: require('../assets/font/SourceSerifPro-SemiBold.ttf'),
      display: Font.FontDisplay.FALLBACK,
    },
    ...MaterialIcons.font,
  });
}
