import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import { Platform } from 'react-native';
import semverCompare from 'semver-compare';

export { URI_PREFIX } from '@src/lib/uriPrefix';
import DevConfig from '@src/config.dev.json';
import ProdConfig from '@src/config.prod.json';

let _androidSemver: string | null = null;
function getAndroidSemverFromBuildVersion() {
  if (_androidSemver) return _androidSemver;
  const ver: string = Constants.nativeBuildVersion ?? '888998877';
  const major = Number.parseInt(ver.substr(2, 3), 10);
  const minor = Number.parseInt(ver.substr(5, 2), 10);
  const patch = Number.parseInt(ver.substr(7, 2), 10);
  _androidSemver = [major, minor, patch].join('.');
  return _androidSemver;
}

export function getNativeBuildVersionAsSemver() {
  return Platform.select({
    android: () => getAndroidSemverFromBuildVersion() ?? '1.0.0',
    web: () => Constants.nativeAppVersion ?? '1.0.0',
    default: () => Constants.nativeBuildVersion ?? '1.0.0',
  })();
}

export function nativeVersionAtLeast(version: string) {
  const nativeBuildVersion = getNativeBuildVersionAsSemver();
  return semverCompare(nativeBuildVersion ?? '99.99.99', version) >= 0;
}

export const isHermes = () => !!(global as unknown as { HermesInternal: boolean }).HermesInternal;

export enum Environment {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
  STAGING = 'staging',
}

export const manifest: Omit<NonNullable<typeof Constants.manifest>, 'bundleUrl'> = {
  slug: Platform.OS === 'web' ? Constants.manifest!.slug : 'oui-aviva',
  ...Updates.manifest,
  // eslint-disable-next-line
  releaseChannel: Updates.releaseChannel ?? (Updates.manifest as any)?.releaseChannel ?? undefined,
  version:
    (Updates.manifest as typeof Constants.manifest)?.version ?? getNativeBuildVersionAsSemver(),
};

export const APP_SLUG = manifest.slug! as
  | 'oui-aviva-staff'
  | 'oui-aviva'
  | 'oui-therapeutics'
  | 'vitahealth'
  | 'corporate';
export const IS_STAFF_APP = APP_SLUG === 'oui-aviva-staff';

const releaseChannel = manifest.releaseChannel
  ? manifest.releaseChannel.split('-')[0]
  : 'development';

export const environment: Environment = Platform.select({
  // CAPS hosts in prod on more TLDs than 'health' so it's easier to match on 'dev'
  web: () =>
    window.location.host.split('.').reverse()[0] === 'dev' ||
    window.location.hostname === 'localhost' ||
    // For firebase hosting preview channels
    window.location.hostname.includes('-dev-')
      ? Environment.STAGING
      : Environment.PRODUCTION,
  default: () =>
    process.env.NODE_ENV === 'test' ||
    Constants.appOwnership === 'expo' ||
    ['default', 'development'].includes(releaseChannel)
      ? Environment.DEVELOPMENT
      : ['test', 'staging'].includes(releaseChannel)
      ? Environment.STAGING
      : Environment.PRODUCTION,
})();
export const IS_PRODUCTION = environment === Environment.PRODUCTION;

export const ENABLE_TTS = false;

// https://github.com/callstack/react-native-paper/blob/master/src/constants.ts
const DEFAULT_STATUSBAR_HEIGHT_EXPO = Constants ? Constants.statusBarHeight : 0;

export const APPROX_STATUSBAR_HEIGHT = Platform.select({
  default: 0,
  android: DEFAULT_STATUSBAR_HEIGHT_EXPO,
  ios: Platform.Version < 11 ? DEFAULT_STATUSBAR_HEIGHT_EXPO : 0,
});

export const CLINICIAN_WIZARD_STEPS = 6;

const Config = IS_PRODUCTION ? ProdConfig : DevConfig;
export const API_URL = Config.API_URL;
export const REST_API_URL = Config.REST_API_URL;
export const BOT_TRANSITION_URL = Config.BOT_TRANSITION_URL;
export const CLOUD_FUNCTION_URL = Config.CLOUD_FUNCTION_URL;
export const AMPLITUDE_API_KEY = (
  {
    'oui-aviva': Config.AMPLITUDE_API_KEY,
    'oui-aviva-staff': Config.AMPLITUDE_API_KEY_STAFF,
  } as Record<string, string>
)[APP_SLUG];
export const SENTRY_DSN = (
  {
    'oui-aviva': Config.SENTRY_DSN,
    'oui-aviva-staff': Config.SENTRY_DSN_STAFF,
    vitahealth: 'https://60056ff0826e4bc7a9bcc021ca00c33d@o282626.ingest.sentry.io/5563249',
    corporate: 'https://8b20411ba4f24e91af49870737a15e06@o282626.ingest.sentry.io/5563251',
    yst: 'https://326c1e1e40df449e8963d6bf7b081fe9@o282626.ingest.sentry.io/5794312',
  } as Record<string, string>
)[APP_SLUG];
export const MUX_ENV_KEY = Config.MUX_ENV_KEY;

export const FLAGS: typeof global['flags'] = {
  allowDarkTheme: false && environment === Environment.DEVELOPMENT && __DEV__,
  showEnvelopeIDInChat: false,
  showI18NFlags: false,
  showI18NKeys: false,
  showLocalCrisisServices: false,
  showSingleStringCheckFormSummary: false,
  useMediaLibraryAlbums: environment !== Environment.PRODUCTION,
  useIOS14DatePicker: false,
};
global.flags = FLAGS;

export const remoteConfigDefaults = {
  apiUrl: API_URL,
  subscriptionUri: Config.SUBSCRIPTION_API_URL,
  restApiUrl: Config.REST_API_URL,
};

export const REGEX = {
  email:
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
  // https://www.regextester.com/17
  phone:
    /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
};

export const SESSION_TIMEOUT =
  environment === Environment.DEVELOPMENT
    ? 40 * 60000
    : manifest.slug === 'oui-aviva'
    ? 3 * 60000
    : 15 * 60000;

// use a non-round number to more easily distiguish this timeout from other 10s timeouts
export const LOADING_TIMEOUT = 10001;

export const DETOX_SIGNATURE = `data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAA0NDQ0ODQ8REQ8VFxQXFR8dGhodHy8iJCIkIi9HLTQtLTQtRz9MPjo+TD9xWU9PWXGDbmhug5+Ojp/Ivsj///8BDQ0NDQ4NDxERDxUXFBcVHx0aGh0fLyIkIiQiL0ctNC0tNC1HP0w+Oj5MP3FZT09ZcYNuaG6Dn46On8i+yP/////CABEIAKoBLAMBIgACEQEDEQH/xAAbAAEBAAMBAQEAAAAAAAAAAAAABAIDBQYBB//aAAgBAQAAAAD9OAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB84ej0WQAAAAAhtQdAAAAAAQ3I7AAAAABHYlqAAAAAHP6DVjvAAAAAPP8AoCSsAAAAA43ZJagAAAACG4iryDDXvAAAAByesTad1IAAAAGmDdjXuAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAH/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAhAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAH//xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oACAEDEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAf//EADoQAAIBAgMCCQcNAAAAAAAAAAECAwQRAAVBElAQFCAhMVJxgbETIlFTgpGhFSQzNEBicHWAo7LC0v/aAAgBAQABPwD9CjEKDc2AFycR0j5mPLVcswhJvDAjlAE0LkWJY4npnyRuN00kxpF+swu5lsvrELYUqwDKQQRcEag7jzC5pzEpsZXWK/oDmxPuvhQFAsLACwGCAwIIuCLEYyy0EL0Zv83bYS/q+lNxyjylbSppGjyH+A8TwtaLMInHRLEyN2odpdxx3auq26gjj9wLf24a6ypHOBzRSo/dfZbcdDKsk2Z6FKvYPaIweGaITRSxnodGU94tilmMtNCx6gv267iyA7T53+Zv8EHIpBsNPDqkxPc/n+JO4smgERzU6nMZT8FHIF1r0Gk0du+M38DuKijkj40HQreqkZb6g2seRXqyxeWRSWgkEotqB0gdoJwjK6K6kFWAII1B5TyRxKWdgq+k4ileTaJiZUFtknmLd2g+3wyfJ1TxKWwie5pT4x9q6ciStpoG2ZJRt9RfOc+yLnAkrJuaOIQr15edu5RiOnRTtsWkk67m57tBuCeCGoieKaJJEYWZWFxg5U0agU2Y1kK6LtCQfuBjbHE64E3zWX2Y0/zhaAn6esqZhoCwjHujAxDTwwC0caoPui34o//EABQRAQAAAAAAAAAAAAAAAAAAAHD/2gAIAQIBAT8AUf/EABQRAQAAAAAAAAAAAAAAAAAAAHD/2gAIAQMBAT8AUf/Z`;

export const DEFAULT_HEADER_MODE = 'float' as const;

export const IOS_14_OR_GREATER =
  Platform.OS === 'ios' &&
  Platform.Version &&
  semverCompare(Platform.Version.toString(), '14.0') >= 0;
