import React from 'react';
import { Platform, StyleProp, TextStyle, ViewStyle } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import Sentry from '@src/sentry';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import LogBox from '@src/lib/LogBox';
import { Text, OldHeading } from '@src/components/Text';
import { View } from '@src/components/View';
import { initFonts } from '@src/lib/initFonts';

import { URI_PREFIX } from '@src/constants';
import { DeeplinkConfigShape } from '@src/types';
import {
  ResponsiveContextProvider,
  useResponsiveSizeContext,
} from '@src/components/ResponsiveContext';
import { I18nProvider } from '@src/lib/i18n';

export function Features({
  children,
  hasDescriptions,
}: {
  children: React.ReactNode;
  hasDescriptions?: boolean;
}) {
  const { size } = useResponsiveSizeContext();
  const isMobile = size === 'small';
  return (
    <Section>
      <View
        row={!isMobile}
        spacing={isMobile ? 24 : hasDescriptions ? 12 : 0}
        style={[
          isMobile
            ? { padding: 32, alignItems: 'flex-start' }
            : { paddingVertical: 40, justifyContent: 'space-around' },
          hasDescriptions ? { alignItems: 'flex-start' } : undefined,
        ]}
      >
        {children}
      </View>
    </Section>
  );
}

export function Feature({
  asset,
  text,
  description,
}: {
  text: string;
  description?: string;
  asset: React.ReactNode;
}) {
  const { size } = useResponsiveSizeContext();
  const isMobile = size === 'small';
  return (
    <View
      spacing={24}
      style={{
        alignItems: 'center',
        flexDirection: isMobile ? 'row' : undefined,
      }}
      row={isMobile}
      flex={description ? 1 : undefined}
    >
      {asset}
      <Text
        text={text}
        size={isMobile ? 17 : 21}
        style={{ lineHeight: isMobile ? 23 : 29 }}
        weight="semibold"
      />
      {description ? (
        <Text text={description} size={isMobile ? 14 : 18} textAlign="center" />
      ) : null}
    </View>
  );
}

export function Row({
  asset,
  assetLast,
  assetAlignment,
  content,
  heading,
  headingStyle,
}: {
  asset: React.ReactNode;
  assetAlignment?: 'center' | 'flex-end';
  assetLast?: boolean;
  content: string | string[];
  heading: string;
  headingStyle?: StyleProp<TextStyle>;
}) {
  const { size } = useResponsiveSizeContext();
  const isMobile = size === 'small';

  const rowProps = {
    row: !isMobile,
    spacing: 90,
    style: { alignItems: 'center' as const },
  };

  const assetOuter = (
    <View
      style={{
        flex: 1,
        alignSelf: 'stretch',
        justifyContent: assetAlignment || 'center',
        alignItems: rowProps.row ? undefined : 'center',
        paddingBottom: rowProps.row ? undefined : 80,
      }}
    >
      {asset}
    </View>
  );

  return (
    <View {...rowProps}>
      {assetLast || !rowProps.row ? null : assetOuter}
      <View
        style={{
          flex: 1,
          paddingTop: 80,
          paddingHorizontal: rowProps.row ? 80 : 40,
          paddingBottom: rowProps.row ? 80 : 0,
        }}
        spacing={typeof content === 'string' ? 24 : 18}
      >
        <OldHeading
          text={heading}
          textAlign={rowProps.row ? undefined : 'center'}
          style={[
            { fontSize: isMobile ? 28 : 32, lineHeight: 43, fontFamily: 'OpenSansSemiBold' },
            headingStyle,
          ]}
        />
        {typeof content === 'string' ? (
          <Text text={content} size={17} style={{ lineHeight: 23 }} />
        ) : (
          <ul style={{ marginLeft: 18 }}>
            {content.map((line, i) => {
              return (
                <li key={i} style={{ marginBottom: 8 }}>
                  <Text text={line} size={isMobile ? 17 : 21} style={{ lineHeight: 29 }} />
                </li>
              );
            })}
          </ul>
        )}
      </View>
      {assetLast || !rowProps.row ? assetOuter : null}
    </View>
  );
}

export function Section({
  backgroundColor,
  children,
  maxWidth = 940,
  style,
  outer,
  nativeID,
}: {
  backgroundColor?: string;
  children?: React.ReactNode;
  maxWidth?: number;
  style?: StyleProp<ViewStyle>;
  outer?: React.ReactNode;
  nativeID?: string;
}) {
  const { width } = useResponsiveSizeContext();

  return (
    <View
      nativeID={nativeID}
      style={[
        {
          backgroundColor,
          overflow: 'hidden',
        },
        style,
      ]}
    >
      {outer}
      <View
        style={{
          alignSelf: 'center',
          flexGrow: 1,
          maxWidth,
          paddingHorizontal: width <= maxWidth ? 20 : 0,
          width: '100%',
        }}
      >
        {children}
      </View>
    </View>
  );
}

LogBox.ignoreLogs(['Accessing view manager configs']);

type Props = {
  app: React.ReactNode;
  linkingConfig?: DeeplinkConfigShape<string>;
};

function LoadedApp({ app, linkingConfig }: Props) {
  return (
    <View style={{ flexGrow: 1, minHeight: 300, width: '100%' }}>
      <style>{`body { font-family: 'OpenSansRegular'}`}</style>
      <I18nProvider>
        <NavigationContainer
          linking={{
            prefixes: [URI_PREFIX as unknown as string],
            config: {
              screens: {
                TermsAndPrivacy: 'terms-and-privacy',
                Home: '',
                ...linkingConfig,
              },
            },
          }}
        >
          <ResponsiveContextProvider>{app}</ResponsiveContextProvider>
        </NavigationContainer>
      </I18nProvider>
    </View>
  );
}

// technically this should be after initFonts resolves, but web has good fallback baked in
global.fontsLoaded = true;

export class StaticWebContainer extends React.Component<
  Props & { beforeLoad?: () => Promise<unknown> }
> {
  state = { loaded: !this.props.beforeLoad };

  componentDidCatch(e: Error, info: object) {
    Sentry.withScope((scope) => {
      scope.setExtra('info', info);
      Sentry.captureException(e);
    });
  }

  componentDidMount() {
    if (Platform.OS === 'web') {
      (this.props.beforeLoad?.() ?? Promise.resolve()).then(() => this.setState({ loaded: true }));
      initFonts();
    }
  }

  render() {
    if (!this.state.loaded) {
      return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <ActivityIndicator size="large" />
        </View>
      );
    }
    return <LoadedApp {...this.props} />;
  }
}
