import React from 'react';
import { StyleSheet, View as RNView, ViewProps } from 'react-native';

type Props = ViewProps & {
  childFlex?: number;
  flex?: number;
  spacing?: number;
  row?: boolean;
  children?: React.ReactNode;
};

export const View = React.forwardRef<RNView, Props>(
  ({ flex, row, style, children, spacing, childFlex, ...props }: Props, ref) => {
    return (
      <RNView
        ref={ref}
        {...props}
        style={[
          // If the row has childFlex, we're likely trying to align items horizontally to take up the same space
          // In the case of form fields, if one has an error, we want them to align top instead of center
          row ? [styles.row, childFlex ? { alignItems: 'flex-start' } : null] : null,
          flex ? { flex } : null,
          style,
        ]}
      >
        {spacing || typeof childFlex === 'number'
          ? React.Children.toArray(children)
              .filter((child) => child !== null)
              .map((child, i) => {
                return (
                  <React.Fragment key={child ? (child as any).key || i : i}>
                    {i > 0 && child ? (
                      <RNView style={row ? { width: spacing } : { height: spacing }} />
                    ) : null}
                    {typeof childFlex === 'number' && React.isValidElement(child)
                      ? React.cloneElement(child, {
                          style: [child.props.style, { flex: childFlex }],
                        })
                      : child}
                  </React.Fragment>
                );
              })
          : children}
      </RNView>
    );
  },
);

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
