import React from 'react';
import { Platform } from 'react-native';
import Svg, { Use as NativeUse, Rect, SvgProps, G, Circle, Path, Ellipse } from 'react-native-svg';

// https://github.com/necolas/react-native-web/commit/4905bb98b7cb314e0a528e505d7e282f692ded3f
// Will be fixed in react-native-web 0.17
const Use = Platform.OS === 'web' ? 'use' : NativeUse;

import { FullLogoSymbol } from '@src/components/ScreenHeader';

const SvgComponent = ({ variant, ...props }: SvgProps & { variant?: 'warm' | 'cool' }) => (
  <Svg viewBox="0 0 320 213" {...props}>
    <FullLogoSymbol />
    <G transform="translate(0 .5)" fill="none" fillRule="evenodd">
      <Circle
        fill={variant === 'warm' ? '#F3E9D2' : '#F4F8F5'}
        cx={115.53}
        cy={105.53}
        r={105.53}
      />
      <G transform="translate(56.89 142.66)" fillRule="nonzero">
        <Circle fill={variant === 'warm' ? '#71A3D7' : '#8B688D'} cx={33.22} cy={3.9} r={3.9} />
        <Path fill="#454154" d="M19.37 23.45h1V68.4h-1z" />
        <Ellipse fill="#454154" cx={19.05} cy={24.42} rx={6.35} ry={6.84} />
        <Path d="M20.52 50.81S19.56 31.12 0 33.4" fill="#454154" />
      </G>
      <G transform="translate(138.22 50.66)">
        <Path
          d="M63.56 0c4.49 0 8.13 3.66 8.13 8.18v140.04c0 4.52-3.64 8.19-8.13 8.19H8.125C3.63 156.41 0 152.74 0 148.22V8.18C0 3.66 3.63 0 8.125 0H63.56z"
          fill="#D8D8D8"
        />
        <Rect width="72" height="160" fill="#FFFFFF" />
        <Use href="#full-logo" x="21" y="-30" width="30" />
      </G>
      <Path
        d="M147.19 47.87h53.62c4.26 0 5.81.44 7.36 1.28 1.56.84 2.79 2.07 3.62 3.64l.05.1c.8 1.54 1.23 3.11 1.23 7.32v137.56c-.02 4.1-.47 5.63-1.28 7.173a8.712 8.712 0 01-3.62 3.637l-.09.05c-1.53.81-3.09 1.24-7.27 1.24h-53.74c-4.07-.02-5.59-.47-7.11-1.29a8.712 8.712 0 01-3.62-3.637l-.05-.103c-.79-1.52-1.22-3.08-1.22-7.19V60.21c0-4.29.44-5.85 1.27-7.42.83-1.57 2.06-2.8 3.62-3.64l.09-.05c1.51-.8 3.06-1.23 7.14-1.23zm54.6 2.79h-7.94V54.32c0 2.07-1.67 3.75-3.73 3.75h-32.11c-2.06 0-3.73-1.68-3.73-3.75v-3.57-.09h-7.94c-4.44 0-8.05 3.59-8.12 8.05v140.18c0 4.47 3.56 8.11 7.99 8.18H201.79c4.44 0 8.05-3.59 8.12-8.05V58.84c0-4.52-3.64-8.18-8.12-8.18z"
        fill="#242226"
      />
      <Path
        d="M201.79 50.66c4.48 0 8.12 3.66 8.12 8.18v140.05c0 4.52-3.64 8.18-8.12 8.18h-55.45c-4.48 0-8.12-3.66-8.12-8.18V58.84c0-4.52 3.64-8.18 8.12-8.18h7.94v.05h0v3.61c0 2.05 1.63 3.72 3.66 3.75h32.18c2.04 0 3.7-1.64 3.73-3.69v-3.63-.09h7.94z"
        stroke="#242226"
        strokeWidth={5}
      />
      <G transform="matrix(-1 0 0 1 283.61 33.22)" fillRule="nonzero">
        <Circle
          fill="#322C3A"
          transform="matrix(-1 0 0 1 65.46 0)"
          cx={32.73}
          cy={19.05}
          r={14.16}
        />
        <Path
          d="M33.98 30.52c-.77 1.14-2.1 4.62-1.51 4.93.59.31 4 .46 6.07 3.1 2.07 2.65-2.52 9.33-2.52 9.33L18.56 35.6l7.1-2.48s2.32-3.79 2.17-6.74l6.15 4.14z"
          fill="#FFB8B8"
        />
        <Path
          d="M34.15 30.28c-.77 1.14-2.27 4.86-1.68 5.17.59.31 4 .46 6.07 3.1 2.07 2.65-2.52 9.33-2.52 9.33L18.56 35.6l7.1-2.48s2.32-3.79 2.17-6.74l6.32 3.9z"
          fill="#000"
          opacity={0.05}
        />
        <Path
          d="M27.96 168.07l-5.71-.35c-4.5-21.71-4.88-31.21-1.12-28.52 2.54 1.83 4.95 1.92 7.21.292l-.38 28.578z"
          fill="#FFB8B8"
        />
        <Path
          d="M27.99 167.82l-5.74.25c-4.5-21.97-4.88-31.59-1.12-28.86 2.54 1.85 4.95 1.94 7.21.29l-.35 28.32z"
          fill="#000"
          opacity={0.1}
        />
        <Path
          d="M7.82 168.07c-2.13-.03-2.52-3.64-1.18-10.83l11.28-27.5c4.3-3.02 7.06-2.09 8.29 2.78 1.19 4.68-3.89 14.16-15.23 28.44-.44.56.03 7.16-3.16 7.11z"
          fill="#FFB8B8"
        />
        <Path
          d="M11.06 166.25c-.31 4.98.67 5.44.66 9.03.02.34-.04.67-.19.97-.12.18-.29.32-.48.41-1.27.62-2.73-.25-3.79-1.21-1.95-1.79-3.61-4.18-3.72-6.88.02-.7 0-1.39-.07-2.09-.17-1.01-.74-1.9-1.24-2.79a23.737 23.737 0 01-2.06-4.8c-.19-.63-.32-1.42.16-1.86.16-.14.35-.24.56-.31l5.54-1.34c.5-.19-1.07 2.75 2.82 6.33.42.38 1.52.44 1.87 1.1.58 1.07.09.9-.06 3.44zM21.16 166.61c.12-.21 1.56-.58 2.73-.47 1.18.11 1.62.16 2.33.39.41.14.72.58.96.84.17.19.7.11.83.428.22.422.32.892.29 1.362.04 1.29.07 2.59-.03 3.88-.09 1.09-.29 2.22-1.05 3.07-1.22 1.36-3.42 1.56-5.37 1.64l-1.59.08c-.43.04-.87.01-1.29-.11-.53-.22-.93-.61-1.12-1.1-.82-1.82.48-3.78 1.28-5.61.93-2.11.76-2.41 2.03-4.4z"
          fill="#454154"
        />
        <Ellipse
          fill="#FFB8B8"
          transform="matrix(-1 0 0 1 65.46 0)"
          cx={32.73}
          cy={22.47}
          rx={8.3}
          ry={7.81}
        />
        <Ellipse
          fill="#322C3A"
          transform="matrix(-1 0 0 1 71.32 0)"
          cx={35.66}
          cy={16.61}
          rx={6.35}
          ry={4.88}
        />
        <Ellipse
          fill="#FFB8B8"
          transform="scale(-1 1) rotate(-13.43 0 232.29)"
          cx={24.97}
          cy={20.21}
          rx={1}
          ry={1.3}
        />
        <Path
          d="M28.25 7.24a7.128 7.128 0 00-9.7-.91c.14-.15.28-.29.43-.43 2.95-2.69 7.16-2.7 9.83.28 2.66 2.99 3.44 7.09.88 10.05-.13.15-.3.26-.46.39 2.56-2.77 1.53-6.57-.98-9.38z"
          fill="#6A6A7C"
        />
        <Path
          d="M22.516 8.996c-3.699-.768-1.875-2.801 1.81-2.036 3.933.822 5.58 4.516 4.612 8.416-.051.207-.244.367-.315.563.606-3.72-2.402-6.168-6.107-6.943z"
          fill="#6A6A7C"
        />
        <Path
          d="M64.1 35.9c.34-1.01 2.49-6.21 3.83-7.88 1.33-1.68 3.79-3.08 4.86-2.47 1.08.6 3.1 1.15-.87 7.92-1.46 2.47-2.28 3.42-3.33 5.16-.48.8-2.47 8.42-3.18 10.52-.71 2.11-3.71 10.06-5.28 11.59-1.7 1.66-4.01.45-5.78-.66-1.98-1.25-12.51-10.5-14.24-11.81-6.81-5.19.18-9.32.44-10.25.11-.42 13.78 13.1 15.37 15.27 1.58 2.17 5.38-9.2 8.18-17.39z"
          fill="#FFB8B8"
        />
        <Path
          d="M14.34 36.26l5.47-1.92s1.3-.39 1.88.11c.74.63 1.24 2.29 2.51 3.55 1.27 1.27 2.36 2.55 4.3 2.56 2.05 0 2.9-.36 3.97-1.55 1.27-1.41.98-3.56 1.81-3.23 4.19 1.66 4.17 1.3 5.44 2.41.7.61.07 1.36.1 4.51.03 3.15.56 5.69.56 5.69s3.05 2.08 1.61 6.86c-1.45 4.79-4.67 4.95-4.18 5.59.48.64.64 4.47.64 4.47s25.07 38.15 22.98 66.56c0 0-2.61 8.61-38.41 9.74-19.46.6-21.05-2.24-19.76-7.18 1.28-4.95 11.73-25.38 10.6-39.91-1.12-14.52 7.72-27.13 7.72-27.13s-1.93-3.99-.97-4.63l-10.6-15.97s0-9.09 4.33-10.53z"
          fill={variant === 'warm' ? '#2A66B0' : '#008689'}
        />
        <Path
          d="M22.96 11.65c-3.3 0-6.05-2.52-6.33-5.79-.01.17-.02.35-.02.52 0 3.49 2.84 6.32 6.35 6.32s6.35-2.83 6.35-6.32c0-.17-.01-.35-.02-.52-.28 3.27-3.03 5.79-6.33 5.79z"
          fill="#322C3A"
        />
        <Circle fill="#322C3A" cx={24.91} cy={6.35} r={6.35} />
        <Path
          d="M14.68 37.31c.42-.2.92-.24 1.37-.09.45.15.81.46.97.86.56 1.36 1.13 4.2.93 10.11-.37 10.86-.56 18.26-.56 18.26V82.9s8.23 15.79 3.74 15.79-7.67-12.5-7.67-12.5-4.12-18.92-2.81-22.21c0 0-3.69-22.73 4.03-26.67z"
          fill="#FFB8B8"
        />
      </G>
    </G>
  </Svg>
);

export default SvgComponent;
