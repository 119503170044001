import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AccessibilityInfo, Keyboard, Platform } from 'react-native';
import OuiModuleNative from '@src/lib/OuiModuleNative';
import { useAppState } from '@src/hooks/useAppState';

const AccessibilityContext = React.createContext({
  isCaptionServiceEnabled: false,
  isKeyboardShowing: false,
  isScreenReaderEnabled: false,
  isAnnouncingIOS: false,
  announceForAccessibility: (announcement: string) => {},
});

export function useAccessibilityContext() {
  return useContext(AccessibilityContext);
}

export function AccessibilityProvider(props: { children?: React.ReactNode }) {
  const [isScreenReaderEnabled, setIsScreenReaderEnabled] = useState(false);
  const [isKeyboardShowing, setIsKeyboardShowing] = useState(false);
  const [isCaptionServiceEnabled, setIsCaptionServiceEnabled] = useState(false);
  const [isAnnouncingIOS, setIsAnnouncingIOS] = useState(false);

  // iOS interrupts when calling announceForAccessibility (vs android which queues announcments
  // to be read one after the other. To workaround, we set state when we are announcing and wait for
  // it to finish before sending more announcements to the system
  const announceForAccessibility = useCallback(
    (announcement: string) => {
      if (Platform.OS === 'ios' && isScreenReaderEnabled) {
        setIsAnnouncingIOS(true);
      }
      AccessibilityInfo.announceForAccessibility(announcement);
    },
    [isScreenReaderEnabled],
  );

  useEffect(() => {
    AccessibilityInfo.isScreenReaderEnabled().then((enabled) => {
      setIsScreenReaderEnabled(enabled && Platform.OS !== 'web');
    });
    AccessibilityInfo.addEventListener('screenReaderChanged', setIsScreenReaderEnabled);
    AccessibilityInfo.addEventListener('announcementFinished', () => {
      setIsAnnouncingIOS(false);
    });
    OuiModuleNative.isCaptionServiceEnabledAsync().then(setIsCaptionServiceEnabled);

    function didShow() {
      setIsKeyboardShowing(true);
    }
    function didHide() {
      setIsKeyboardShowing(false);
    }

    Keyboard.addListener('keyboardDidShow', didShow);
    Keyboard.addListener('keyboardDidHide', didHide);

    return () => {
      AccessibilityInfo.removeEventListener('screenReaderChanged', setIsScreenReaderEnabled);
      Keyboard.removeListener('keyboardDidShow', didShow);
      Keyboard.removeListener('keyboardDidHide', didHide);
    };
  }, []);

  useAppState((appState) => {
    if (appState === 'active') {
      OuiModuleNative.isCaptionServiceEnabledAsync().then(setIsCaptionServiceEnabled);
    }
  });

  const value = useMemo(() => {
    return {
      isCaptionServiceEnabled,
      isKeyboardShowing,
      isScreenReaderEnabled,
      isAnnouncingIOS,
      announceForAccessibility,
    };
  }, [
    isCaptionServiceEnabled,
    isKeyboardShowing,
    isScreenReaderEnabled,
    isAnnouncingIOS,
    announceForAccessibility,
  ]);

  return (
    <AccessibilityContext.Provider value={value}>{props.children}</AccessibilityContext.Provider>
  );
}
