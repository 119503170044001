import React, { useCallback, useEffect } from 'react';
import { IntlProvider, IntlShape, useIntl } from 'react-intl';
import IntlMessageFormat, { PrimitiveType } from 'intl-messageformat';
import Sentry from '@src/sentry';
import * as Localization from 'expo-localization';

import { useAppContext } from '@src/hooks/useAppContext';
import { Environment, environment, IS_PRODUCTION } from '@src/constants';

const INVALID_FLAG = '🚫';
const DEFAULT_LOCALE = 'en-US';
const FLAGS: { [key: string]: string } = {
  en: ' 🇺🇸',
  es: '🇲🇽',
};

type Variables = Record<string, PrimitiveType>;

export const LOCALES = Object.keys(FLAGS);

function wrapInFlags(text: string, locale: string) {
  const flag = FLAGS[locale] ?? FLAGS[locale.split('-')[0]] ?? locale;
  return `${flag}${text}${flag}`;
}

export type { IntlShape };

// In production we dont want to override $t to show our flags and we'd rather not take a perf hit
export const useI18n = IS_PRODUCTION
  ? useIntl
  : (): IntlShape => {
      const {
        flags: { showI18NFlags, showI18NKeys },
      } = useAppContext();
      const intl = useIntl();
      const $t: typeof intl['formatMessage'] = useCallback(
        (...args) => {
          if (showI18NKeys) return args[0].id!;
          const result = intl.formatMessage(...args) as string;
          const hasMessage = !!intl.messages[args[0].id!];
          return showI18NFlags
            ? intl.locale === 'en' || hasMessage
              ? wrapInFlags(result, intl.locale)
              : `${INVALID_FLAG}${result}${INVALID_FLAG}`
            : result;
        },
        [intl, showI18NFlags, showI18NKeys],
      );
      return { ...intl, $t };
    };

export function formatTemplate(
  messageTemplate: string,
  variables?: Variables,
  options?: { wrapInFlags?: boolean; locale: string },
) {
  const template = new IntlMessageFormat(messageTemplate, options?.locale ?? DEFAULT_LOCALE);
  const result = template.format<string>(variables) as string;
  return options?.wrapInFlags ? wrapInFlags(result, options?.locale ?? DEFAULT_LOCALE) : result;
}

function getMessages(locale: string) {
  const lang = locale.split('-')[0];
  switch (lang) {
    case 'en': {
      return environment === Environment.DEVELOPMENT
        ? {} // forces use of defaultMessage
        : require('@src/messages/compiled/en.json');
    }
    default:
      return {};
  }
}

export function I18nProvider({ children }: React.PropsWithChildren<{}>): React.ReactElement {
  const { locale } = useAppContext();

  useEffect(() => {
    if (
      '__setDefaultTimeZone' in Intl.DateTimeFormat &&
      // iOS simulator on CI has GMT and it results in an error to __setDefaultTimeZone and would
      // essentially be a noop anyway since GMT = UTC
      Localization.timezone !== 'GMT'
    ) {
      try {
        // @ts-expect-error
        Intl.DateTimeFormat.__setDefaultTimeZone(Localization.timezone);
      } catch (e) {
        Sentry.captureException(e, { extra: { timezone: Localization.timezone } });
      }
    }
  }, []);

  return React.createElement(
    IntlProvider,
    {
      // not yet ready to serve other locales
      locale: environment === Environment.PRODUCTION ? 'en' : locale,
      messages: getMessages(locale),
      defaultLocale: 'en',
      fallbackOnEmptyString: false,
      onError: (err) => {
        if (locale.startsWith('en') && environment !== Environment.PRODUCTION) {
          if (environment === Environment.DEVELOPMENT) return;
          Sentry.captureMessage('i18n warning for en locale', { extra: { err, locale } });
        } else {
          Sentry.captureException(err, { extra: { locale } });
        }
      },
    },
    children,
  );
}
