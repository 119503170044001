import Sentry from '@src/sentry';
import Amplitude from '@src/amplitude';

export const Severity = Sentry.Severity;

export async function setUser({
  userID,
  email,
}: {
  userID?: string | null;
  email?: string | null;
}) {
  const sentryPayload: Parameters<typeof Sentry.setUser>[0] = userID ? { id: userID } : null;
  if (sentryPayload && email) {
    sentryPayload.email = email;
  }
  Sentry.setUser(sentryPayload);
  await Amplitude.getInstance().setUserIdAsync(userID ?? null);
}

export function setUserProperties(
  properties: Record<string, number | string | boolean | undefined>,
) {
  return Amplitude.getInstance().setUserPropertiesAsync(properties);
}

export const addBreadcrumb: typeof Sentry.addBreadcrumb = (crumb) => {
  Sentry.addBreadcrumb(crumb);
  if (crumb.category !== 'mqtt-connection') {
    const eventName =
      crumb.category && crumb.message
        ? `${crumb.category}:${crumb.message}`
        : crumb.category || crumb.message || 'Unknown event';
    if (crumb.data) {
      Amplitude.getInstance().logEventWithPropertiesAsync(eventName, crumb.data);
    } else {
      Amplitude.getInstance().logEventAsync(eventName);
    }
  }
};

export const logEvent = (
  eventName: string,
  data?: Record<string, string | number | boolean | null>,
) => {
  if (data) {
    return Amplitude.getInstance().logEventWithPropertiesAsync(eventName, data);
  } else {
    return Amplitude.getInstance().logEventAsync(eventName);
  }
};
