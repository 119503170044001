// Adapted from https://github.com/CharlesStover/use-dimensions/pull/4
import React, { createContext, useContext, useRef, useMemo } from 'react';
// import { Dimensions as RNDimensions } from 'react-native';
// import debounce from 'lodash/debounce';
import { recordKeys } from '@src/lib/recordKeys';
import { useWindowDimensions } from '@src/hooks/useWindowDimensions';

const DEFAULT_BREAKPOINTS = {
  default: 940,
  medium: 600,
  small: 0,
};

export type BreakpointNames = keyof typeof DEFAULT_BREAKPOINTS;

type ResponsiveContextValue = { size: BreakpointNames; width: number; height: number };

const ResponsiveContext = createContext<ResponsiveContextValue>({
  size: 'default',
  height: 0,
  width: 0,
});

function useResponsiveSize(config = DEFAULT_BREAKPOINTS): ResponsiveContextValue {
  const configRef = useRef(config);
  const { height, width } = useWindowDimensions();
  return useMemo(() => {
    const keys = recordKeys(configRef.current);
    return { width, height, size: keys.find((key) => width >= configRef.current[key]) ?? keys[0] };
  }, [height, width]);
}

export function ResponsiveContextProvider({ children }: { children: React.ReactNode }) {
  const value = useResponsiveSize();
  return <ResponsiveContext.Provider value={value}>{children}</ResponsiveContext.Provider>;
}

export function useResponsiveSizeContext() {
  return useContext(ResponsiveContext);
}
