import { useRef, useEffect } from 'react';
import { AppState, AppStateStatus } from 'react-native';

export function useAppState(listener: (nextAppState: AppStateStatus) => void) {
  const listenerRef = useRef(listener);
  listenerRef.current = listener;
  useEffect(() => {
    const fullListener = (nextAppState: AppStateStatus) => {
      if (global.pauseAppState) return;
      listenerRef.current(nextAppState);
    };
    AppState.addEventListener('change', fullListener);
    return () => AppState.removeEventListener('change', fullListener);
  }, []);
}
