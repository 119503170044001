import { createElement, createContext, useContext } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { useColorScheme } from 'react-native-appearance';
import ColorObj from 'color';
import merge from 'lodash/merge';

import { useAppContext } from '@src/hooks/useAppContext';

const StyleGuideColors = {
  LogoCyan: '#008689',
  LogoLilac: '#8b688d',

  BrandCarminePink: '#ae595b',
  BrandPastelPink: '#ebaca2',
  BrandJetStreamGreen: '#bed3c3',
  BrandSpaceBlue: '#202e53',

  Gray1: '#242226',
  Gray2: '#322c3a',
  Gray3: '#454154',
  Gray4: '#6a6a7c',
  Gray5: '#9191a3',
  Gray6: '#c5c5d3',
  Gray7: '#efeff4',
  Gray8: '#f7f7f9',

  Danger: '#cb3d3d',
  Success: '#128918',
  Warning: '#efc745',
};

export const Color = {
  accent: StyleGuideColors.BrandCarminePink,
  highlighterColor: '#F7EEEF',
  heading: '#262e44',
  primary: '#4a919e', // teal
  secondary: StyleGuideColors.BrandJetStreamGreen, // light green
  tertiary: StyleGuideColors.BrandSpaceBlue,
  text: StyleGuideColors.Gray1,
  error: StyleGuideColors.Danger,
  success: StyleGuideColors.Success,
  warning: StyleGuideColors.Warning,
  grayBackground: StyleGuideColors.Gray8,
  backgroundColor: 'white',
  surfaceColor: 'white',

  styleGuide: StyleGuideColors,
};

export const Shadow = StyleSheet.create({
  none: {
    elevation: 0,
    shadowColor: 'transparent',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    shadowRadius: 5,
  },
  default: {
    elevation: 2,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
  },
  low: {
    elevation: 2,
    shadowColor: StyleGuideColors.Gray1,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
  },
  medium: {
    elevation: 4,
    shadowColor: StyleGuideColors.Gray1,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 10,
  },
  high: {
    elevation: 6,
    shadowColor: StyleGuideColors.Gray1,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 16,
  },
  overlay: {
    elevation: 16,
    shadowColor: StyleGuideColors.Gray1,
    shadowOffset: { width: 0, height: 20 },
    shadowOpacity: 0.3,
    shadowRadius: 50,
  },
});

export const card = [
  {
    backgroundColor: 'white',
    borderRadius: 16,
  },
  Shadow.high,
];

export const CardContainer = [
  {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
  },
];

export const ScrollContainer = [
  {
    backgroundColor: Color.styleGuide.Gray8,
    padding: 20,
  },
];

export const DarkColor: typeof Color = {
  ...Color,
  accent: new ColorObj(Color.accent).saturate(0.2).hex(),
  backgroundColor: '#000000',
  surfaceColor: '#1a1a1a',
  text: 'white',
  tertiary: 'white',
};

type FontWeight = 'normal' | 'bold' | 'semibold';
type FontFamily = 'sans-serif' | 'serif';

export type Theme = {
  button: {
    small: ButtonTheme;
    normal: ButtonTheme;
    large: ButtonTheme;
  };
  color: {
    primary100: string;
    primary200: string;
    primary300: string;
    accent100: string;
    accent200: string;
    accent300: string;
    accentTwo100: string;
    accentThree100: string;
    dark: string;
    gray100: string;
    gray200: string;
    gray300: string;
    gray400: string;
    gray500: string;
    gray600: string;
    gray700: string;
    gray800: string;
    success: string;
    warning: string;
    danger: string;
  };
  typography: {
    display: FontTheme;
    heading1: FontTheme;
    heading2: FontTheme;
    heading3: FontTheme;
    lead: FontTheme;
    label: FontTheme;
    body: FontTheme;
    small: FontTheme;
  };
  checkbox: {
    size: number;
    groupLabel: keyof Theme['typography'];
    optionLabel: keyof Theme['typography'];
    optionSpacing: number;
    optionLabelSpacing: number;
  };
};

export type FontTheme = {
  size: number;
  lineHeight: number;
  color?: keyof Theme['color'];
  weight?: FontWeight;
  family?: FontFamily;
};

type ButtonTheme = {
  fontSize: number;
  lineHeight: number;
  iconSize: number;
  paddingHorizontal: number;
  paddingVertical: number;
  borderRadius: number;
};

export const DEFAULT_THEME: Theme = {
  button: {
    small: {
      paddingVertical: 3,
      paddingHorizontal: 15,
      fontSize: 15,
      lineHeight: 20,
      borderRadius: 22,
      iconSize: 14,
    },
    normal: {
      paddingVertical: 8,
      paddingHorizontal: 20,
      fontSize: 17,
      lineHeight: 23,
      borderRadius: 22,
      iconSize: 18,
    },
    large: {
      paddingVertical: 28,
      paddingHorizontal: 30,
      fontSize: 21,
      lineHeight: 20,
      borderRadius: 80,
      iconSize: 26,
    },
  },
  color: {
    primary100: '#ae595b',
    primary200: '#ce6a6b',
    primary300: '#ebaca2',
    accent100: '#bed3c3',
    accent200: '#ebf1ed',
    accent300: '#f4f8f5',
    accentTwo100: '#008689',
    accentThree100: '#8b688d',
    dark: '#202e53',
    gray100: '#242226',
    gray200: '#322c3a',
    gray300: '#454154',
    gray400: '#6a6a7c',
    gray500: '#9191a3',
    gray600: '#c5c5d3',
    gray700: '#efeff4',
    gray800: '#f7f7f9',
    success: '#128918',
    warning: '#ecbf2d',
    danger: '#932a2a',
  },
  typography: {
    display: {
      size: 28,
      lineHeight: 35,
      weight: 'semibold',
      family: 'serif',
    },
    heading1: {
      size: 28,
      lineHeight: 38,
      weight: 'bold',
    },
    heading2: {
      size: 21,
      lineHeight: 29,
      weight: 'bold',
    },
    heading3: {
      size: 17,
      lineHeight: 23,
      weight: 'bold',
    },
    lead: {
      size: 21,
      lineHeight: 29,
      weight: 'semibold',
    },
    label: {
      size: 17,
      lineHeight: 23,
      weight: 'semibold',
    },
    body: {
      size: 17,
      lineHeight: 20,
    },
    small: {
      size: 13,
      lineHeight: 20,
      weight: 'semibold',
    },
  },
  checkbox: {
    size: 25,
    optionLabel: 'label',
    groupLabel: 'label',
    optionSpacing: 20,
    optionLabelSpacing: 15,
  },
};

function mergeTheme(toMerge: RecursivePartial<Theme>) {
  return merge({}, DEFAULT_THEME, toMerge);
}

const webDefault = mergeTheme({
  typography: {
    body: {
      size: 15,
      lineHeight: 20,
    },
    label: {
      size: 15,
      lineHeight: 20,
    },
  },
  checkbox: {
    size: 20,
    groupLabel: 'label',
    optionLabel: 'body',
    optionSpacing: 10,
    optionLabelSpacing: 10,
  },
});

const ThemeContext = createContext<Theme>(Platform.OS === 'web' ? webDefault : DEFAULT_THEME);

export function ThemeProvider({ theme, children }: { theme: Theme; children?: React.ReactNode }) {
  return createElement(ThemeContext.Provider, { value: theme }, children);
}

export function useTheme() {
  const theme = useContext(ThemeContext);
  const { flags } = useAppContext();
  const _scheme = useColorScheme();
  const scheme = flags.allowDarkTheme ? _scheme : 'light';
  const color = scheme === 'dark' ? DarkColor : Color;
  return { scheme, Color: color, Shadow, theme };
}

export const CHAT_PREVIEW_BORDER_RADIUS = 15;
