import { NativeModules } from 'react-native';

const isCaptionServiceEnabledAsync = () => Promise.resolve(false);

const OuiModule: {
  clearFrescoDiskCache: () => void;
  clearFrescoMemoryCache: () => void;
  isCaptionServiceEnabledAsync: () => Promise<boolean>;
} = NativeModules.OuiModule ?? {
  clearFrescoDiskCache: () => {},
  clearFrescoMemoryCache: () => {},
  isCaptionServiceEnabledAsync,
};

if (!OuiModule.isCaptionServiceEnabledAsync) {
  OuiModule.isCaptionServiceEnabledAsync = isCaptionServiceEnabledAsync;
}

export default OuiModule;
